
let fontFamily = 'Lusitana'
let tick_size = 23;
let tick_size_dashboard = 12;

export const basicOptions = {
  maintainAspectRatio: false,
  devicePixelRatio: 2,
  legend: {
    display: true,
    align: 'center',
    labels:{
      fontSize: 23,
      fontColor: '#000',
      padding: 50,
      fontFamily
    }
  },
  responsive: true,
};

let basicOptionsDashboard = {
  maintainAspectRatio: false,
  devicePixelRatio: 2,
  legend: {
    display: true,
    align: 'center',
    labels:{
      fontSize: 15,
      fontColor: '#000',
      padding: 15,
      fontFamily
    }
  },
  responsive: true,
};

let scaleLabelOptions = {
  display: true,
  fontSize: 23,
  fontColor: '#000',
  padding: 15,
  fontFamily
}

let scaleLabelOptionsDashboard = {
  display: true,
  fontSize: 15,
  fontColor: '#000',
  padding: 15,
  fontFamily
}

let gridLines = {
  display: true,
  drawBorder: false,
  color: 'transparent',
  zeroLineColor: "black",
}

export let blueChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 60,
        suggestedMax: 125,
        padding: 20,
        fontColor: "#2380f7"
      }
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#2380f7"
      }
    }]
  }
}

export let purpleChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 60,
        suggestedMax: 125,
        padding: 20,
        fontColor: "#9a9a9a"
      }
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(225,78,202,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#9a9a9a"
      }
    }]
  }
}

export let orangeChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 50,
        suggestedMax: 110,
        padding: 20,
        fontColor: "#ff8a76"
      }
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(220,53,69,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#ff8a76"
      }
    }]
  }

}
export let greenChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 50,
        suggestedMax: 125,
        padding: 20,
        fontColor: "#000"
      }
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(0,242,195,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#000"
      }
    }]
  }
}

export let barChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 60,
        suggestedMax: 120,
        padding: 20,
        fontColor: "#000"
      }
    }],
    xAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#000"
      }
    }]
  }

}

export let dispChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [
      {
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 17,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          callback: function(value, index, values) {
            if(value>16){
              return '';
            }
            return value;
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Horas de operación (hrs)',
        },
        
      },
      {
        id: 'B',
        position: 'right',
        grace: '%',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 100,
          max: 110,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          maxTicksLimit: 11,
          callback: function(value, index, values) {
            if(value>100){
              return '';
            }
            return value + '%';
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Disponibilidad (%)',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }

}

export let dispChartOptionsEn = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest"
  },
  scales: {
    yAxes: [
      {
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 17,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          callback: function(value, index, values) {
            if(value>16){
              return '';
            }
            return value;
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Day duration (hrs)',
        },
        
      },
      {
        id: 'B',
        position: 'right',
        grace: '%',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 100,
          max: 110,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          maxTicksLimit: 11,
          callback: function(value, index, values) {
            if(value>100){
              return '';
            }
            return value + '%';
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Availabilty (%)',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }

}

export let genChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    callbacks: {
      label: function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        let value = tooltipItem.value;

        if(tooltipItem.datasetIndex==1){
          return label + ': ' + value;
        }

        return label + ': ' + value + '%';
      },
    }
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          padding: 5,
          fontColor: '#000',
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Generación Fotovoltaica (kWh)',
        }
      },
      {
        position: 'right',
        id: 'B',
        grace: '%',
        gridLines,
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        },
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 5,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          maxTicksLimit: 6,
          callback: function(value, index, values) {
            return value + '%';
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Performance Ratio (%)',
        },
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 5,
        fontColor: "#000",
        maxTicksLimit: 15,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }

}

export let genChartOptionsEn = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    callbacks: {
      label: function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        let value = tooltipItem.value;

        if(tooltipItem.datasetIndex==1){
          return label + ': ' + value;
        }

        return label + ': ' + value + '%';
      },
    }
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          padding: 5,
          fontColor: '#000',
          callback: function(value, index, values) {
            return Intl.NumberFormat('en-US').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Energy Yield (kWh)',
        }
      },
      {
        position: 'right',
        id: 'B',
        grace: '%',
        gridLines,
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        },
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 5,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          maxTicksLimit: 6,
          callback: function(value, index, values) {
            return value + '%';
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Performance Ratio (%)',
        },
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 5,
        fontColor: "#000",
        maxTicksLimit: 15,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }

}

export let genFChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          // suggestedMin: 50,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Potencia [kW]',
        }
      },
      {
        position: 'right',
        id: 'B',
        gridLines: {
          drawBorder: false,
          color: 'grey',
          zeroLineColor: "transparent",
        },
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        },
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 0.1,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Radiación [W/m2]',
        }
      },
    ],
    xAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: tick_size,
        fontFamily,
        fontColor: "#000",
        maxTicksLimit: 10
      }
    }]
  }
}

export let genNFInversoresChartOptions = {
  ...basicOptions,
  legend: {
    labels: {
      fontSize: 8,
      padding: 10,
    }
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          // suggestedMin: 50,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          maxTicksLimit: 10,
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Potencia [kW]',
        }
      },
      {
        position: 'right',
        id: 'B',
        gridLines: {
          drawBorder: false,
          color: 'grey',
          zeroLineColor: "transparent",
        },
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        },
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 0.1,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Radiación [W/m2]',
        }
      },
    ],
    xAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 10,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }
}

export let genFInversoresChartOptions = {
  ...basicOptions,
  legend: {
    labels: {
      fontSize: 8,
      padding: 10,
    }
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          // suggestedMin: 50,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          maxTicksLimit: 10,
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Potencia [kW]',
        }
      },
      {
        position: 'right',
        id: 'B',
        gridLines: {
          drawBorder: false,
          color: 'grey',
          zeroLineColor: "transparent",
        },
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        },
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 0.1,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Radiación [W/m2]',
        }
      },
    ],
    xAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 10,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }
}

export let genNFChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          // suggestedMin: 50,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Potencia [kW]',
        }
      },
      {
        position: 'right',
        id: 'B',
        gridLines: {
          drawBorder: false,
          color: 'grey',
          zeroLineColor: "transparent",
        },
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        },
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          suggestedMax: 0.1,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Radiación [W/m2]',
        }
      },
    ],
    xAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 10,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }
}

export let genHistoricaChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Generación (kWh)',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 10,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }

}

export let genHistoricaChartOptionsEn = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('en-US').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Energy Yield (kWh)',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 10,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }

}


export let radHistoricaChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Radiación (kWh/m2)',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 10,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }

}


export let radHistoricaChartOptionsEn = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('en-US').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Radiation (kWh/m2)',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 10,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }

}


export let autoconsumoChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Energía (kWh)',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 10,
        fontSize: tick_size,
        fontFamily,
      }
    }]
  }

}


export let dashboardPRChartOptions = {
  ...basicOptionsDashboard,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          // callback: function(value, index, values) {
          //   return Intl.NumberFormat('es-CL').format(value);
          // }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'PR  [%]',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
      }
    }]
  }
}


export let dashboardMensualChartOptions = {
  ...basicOptionsDashboard,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
    callbacks: {
      label: function(tooltipItem, data) {
        const datasetIndex = tooltipItem.datasetIndex;

        const label = data.datasets[datasetIndex].label || '';
        const value = tooltipItem.yLabel;

        return `${label}: ${value.toLocaleString('es-CL', {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          roundingMode: "trunc",
          // trailingZeroDisplay: "stripIfInteger"
        })} [kWh]`
      },
    }
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Generación [kWh]',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
      }
    }]
  }
}


export let dashboardMensualIrrChartOptions = {
  ...basicOptionsDashboard,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
    callbacks: {
      label: function(tooltipItem, data) {
        const datasetIndex = tooltipItem.datasetIndex;

        const label = data.datasets[datasetIndex].label || '';
        const value = tooltipItem.yLabel;

        return `${label}: ${value.toLocaleString('es-CL', {
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
          roundingMode: "trunc",
          // trailingZeroDisplay: "stripIfInteger"
        })} [W/m2]`
      },
    }
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Irradiación [W/m2]',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
      }
    }]
  }
}

export let dashboardRendChartOptions = {
  ...basicOptionsDashboard,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          // callback: function(value, index, values) {
          //   return Intl.NumberFormat('es-CL').format(value);
          // }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Rendimiento Específico [kWh/kWp]',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
      }
    }]
  }
}

export let dashboardDispChartOptions = {
  ...basicOptionsDashboard,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          // callback: function(value, index, values) {
          //   return Intl.NumberFormat('es-CL').format(value);
          // }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Disponibilidad [%]',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
      }
    }]
  }
}

export let dashboardGenChartOptions = {
  ...basicOptionsDashboard,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
    callbacks: {
      label: function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        let value = Number.parseFloat(tooltipItem.value);

        return label + ': ' + value.toLocaleString('es-CL') + ' [kWh]';
      },
    }
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Generación [kWh]',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
      }
    }]
  }
}


export let dashboardGenPortafoliosChartOptions = {
  ...basicOptionsDashboard,
  legend: {
    display: true,
    align: 'center',
    labels:{
      fontSize: 15,
      fontColor: '#000',
      padding: 15,
      fontFamily,
      filter: function(item) {
        return item.text !== 'Diferencia';
      }
    }
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
    callbacks: {
      label: function(tooltipItem, data) {
        const datasetIndex = tooltipItem.datasetIndex;

        let label = data.datasets[datasetIndex].label || '';
        let value = Number.parseFloat(tooltipItem.value);

        const unidad = datasetIndex === 2 ? '%' : ' [kWh]';

        return `${label}: ${value.toLocaleString('es-CL')}${unidad}`
      },
      labelTextColor: function(tooltipItem, data) {
        const datasetIndex = tooltipItem.datasetIndex;

        if(datasetIndex !== 2){
          return '#666';
        }
        
        const value = Number.parseFloat(tooltipItem.value);

        if(value < 0){
          return 'red';
        }
        return 'green';
        
      }
    }
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Generación [kWh]',
        }
      },
      {
        position: 'right',
        id: 'B',
        gridLines,
        display: false,
        ticks: {
          display: false,
          fontSize: tick_size_dashboard,
          fontFamily,
          // suggestedMin: 0,
          // suggestedMax: 100,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        // scaleLabel: {
        //   ...scaleLabelOptionsDashboard,
        //   labelString: 'Generación [kWh]',
        // }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
      }
    }]
  }
}


export let dashboardIrrChartOptions = {
  ...basicOptionsDashboard,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Irradiación [kWh/m2]',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
        // maxRotation: 90,
        // minRotation: 90
      }
    }]
  }
}

export let dashboardIrrPondChartOptions = {
  ...basicOptionsDashboard,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Irradiación [kWh/m2]',
        }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
        // maxRotation: 90,
        // minRotation: 90
      }
    }]
  }
}


export let dashboardIrrPondPortafoliosChartOptions = {
  ...basicOptionsDashboard,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
    callbacks: {
      label: function(tooltipItem, data) {
        const datasetIndex = tooltipItem.datasetIndex;

        let label = data.datasets[datasetIndex].label || '';
        let value = Number.parseFloat(tooltipItem.value);

        const unidad = datasetIndex === 2 ? '%' : ' [kWh/m2]';

        return `${label}: ${value.toLocaleString('es-CL')}${unidad}`
      },
      labelTextColor: function(tooltipItem, data) {
        const datasetIndex = tooltipItem.datasetIndex;

        if(datasetIndex !== 2){
          return '#666';
        }
        
        const value = Number.parseFloat(tooltipItem.value);

        if(value < 0){
          return 'red';
        }
        return 'green';
        
      }
    }
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size_dashboard,
          fontFamily,
          suggestedMin: 0,
          // suggestedMax: 24,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptionsDashboard,
          labelString: 'Irradiación [kWh/m2]',
        }
      },
      {
        position: 'right',
        id: 'B',
        gridLines,
        display: false,
        ticks: {
          display: false,
          fontSize: tick_size_dashboard,
          fontFamily,
          // suggestedMin: 0,
          // suggestedMax: 100,
          padding: 10,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          // maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        // scaleLabel: {
        //   ...scaleLabelOptionsDashboard,
        //   labelString: 'Generación [kWh]',
        // }
      },
    ],
    xAxes: [{
      gridLines,
      ticks: {
        padding: 10,
        fontColor: "#000",
        maxTicksLimit: 50,
        fontSize: tick_size_dashboard,
        fontFamily,
        // maxRotation: 90,
        // minRotation: 90
      }
    }]
  }
}


export let graficoDiasOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          // suggestedMin: 50,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          maxTicksLimit: 10,
          callback: function(value, index, values) {
            return Intl.NumberFormat('es-CL').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Potencia (kW)',
        }
      },
    ],
    xAxes: [{
      gridLines,
      type: 'time',
      distribution: 'series',
      time: {
        unit: 'hour',
        stepSize: 1,
        displayFormats: {
          hour: 'H:mm'
        }
      },
      ticks: {
        // source: 'data',
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 12,
        fontSize: tick_size,
        fontFamily,
        // callback: function(value, index, values) {
        //   return value.minute() == 0 ? value.format('HH:mm') : '';
        // }
      }
    }]
  }
}


export let graficoDiasOptionsEn = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: 0,
    position: "nearest",
    display: false,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'A',
        gridLines,
        ticks: {
          display: true,
          fontSize: tick_size,
          fontFamily,
          // suggestedMin: 50,
          // suggestedMax: 24,
          padding: 20,
          // fontColor: "#ff8a76"
          fontColor: '#000',
          maxTicksLimit: 10,
          callback: function(value, index, values) {
            return Intl.NumberFormat('en-US').format(value);
          }
        },
        scaleLabel: {
          ...scaleLabelOptions,
          labelString: 'Power (kW)',
        }
      },
    ],
    xAxes: [{
      gridLines,
      type: 'time',
      distribution: 'series',
      time: {
        unit: 'hour',
        stepSize: 1,
        displayFormats: {
          hour: 'H:mm'
        }
      },
      ticks: {
        // source: 'data',
        padding: 20,
        fontColor: "#000",
        maxTicksLimit: 12,
        fontSize: tick_size,
        fontFamily,
        // callback: function(value, index, values) {
        //   return value.minute() == 0 ? value.format('HH:mm') : '';
        // }
      }
    }]
  }
}

<template>
  <div class="page">
    <div class="subpage report-cover">
      <div class="row cover-header">
        <div class="left">
          {{ localI18n.t('reporte.cover.reporte_mensual') }} <br>
          Delta Activos<br>
          Los Conquistadores 1730, Piso 21 | Santiago | Chile
        </div>
        <div class="right">
          <img
            v-if="portafolio == 'ENELX'"
            class="cover-logo"
            src="/img/enelx.png"
            alt="Enel X Logo"
          >
          <img
            v-else
            class="cover-logo"
            src="/img/delta.png"
            alt="Deltactivos Logo"
          >
        </div>
      </div>

      <div class="row" id="cover-title">
        <h3>{{ localI18n.t('reporte.cover.titulo') }}</h3>
      </div>
      <div class="row" id="cover-title-2">
        <h1>
          {{ localI18n.t('reporte.cover.planta_solar') }}<br>
            {{nombre_visible}} <br>
            {{p_nom}} kW <br>
            <span style="text-transform: capitalize">
              {{formatDate(fecha_reporte, 'MMMM YYYY')}}
            </span>
        </h1>
      </div>

      <div class="row" id="cover-info">
        <h3>
          <span style="text-transform: capitalize">
            {{formatDate(fecha_creacion, 'LL')}}
          </span>
        </h3>   
      </div>

      <div class="row cover-contact-info">
        <h4 v-if="grupo_portafolio !== 'PMGD'">
          <span class="contact-name">Diego Sobarzo Bastías</span><br>
          Gerente General<br>
          d.sobarzo@deltactivos.com <br>
        </h4>
        <template v-if="grupo_portafolio == 'PMGD'"> 
          <h4>
            <span class="contact-name">Ignacio Baeza Tapia</span><br>
            Jefe de Confiabilidad PMGD/PMG <br>
            i.baeza@deltactivos.com <br>
          </h4>
          <h4 v-if="portafolio === 'TRN'">
            <span class="contact-name">Kevin Larenas</span><br>
            Ing. de Confiabilidad PMGD/PMG <br>
            k.larenas@deltactivos.com <br>
          </h4>
          <h4 v-else>
            <span class="contact-name">Gustavo Contreras Jofré</span><br>
            Ing. de Confiabilidad PMGD/PMG <br>
            g.contreras@deltactivos.com <br>
          </h4>
        </template>
        <template v-else> 
          <h4>
            <span class="contact-name">Srdjan Franjola Franjola</span><br>
            Jefe de Confiabilidad <br>
            s.franjola@deltactivos.com <br>
          </h4>
          <h4 v-if="portafolio == 'SINVEST1'">
            <span class="contact-name">Ariel Zúñiga Brito</span><br>
            Ing. de Confiabilidad <br>
            a.zuniga@deltactivos.com <br>
          </h4>
          <h4 v-else-if="portafolio=='IMELSA'">
            
          </h4>
          <h4 v-else-if="portafolio=='DAGDA'">
            <span class="contact-name">Marcelo Valderrama</span><br>
            Ing. de Confiabilidad <br>
            m.valderrama@deltactivos.com <br>
          </h4>
          <h4 v-else-if="portafolio=='SINVEST2'">
            <span class="contact-name">Marcelo Valderrama</span><br>
            Ing. de Confiabilidad <br>
            m.valderrama@deltactivos.com <br>
          </h4>
          <h4 v-else-if="portafolio=='SINVEST3'">
            <span class="contact-name">Marcelo Valderrama</span><br>
            Ing. de Confiabilidad <br>
            m.valderrama@deltactivos.com <br>
          </h4>
          <h4 v-else-if="portafolio=='ENELX'">
            <span class="contact-name">José Majmud Orcos</span><br>
            Ing. de Confiabilidad <br>
            j.majmud@deltactivos.com <br>
          </h4>
          <h4 v-else-if="portafolio=='4TC'">
            <span class="contact-name">José Majmud Orcos</span><br>
            Ing. de Confiabilidad <br>
            j.majmud@deltactivos.com <br>
          </h4>
          <h4 v-else-if="portafolio=='ENLASA'">
            <span class="contact-name">José Majmud Orcos</span><br>
            Ing. de Confiabilidad <br>
            j.majmud@deltactivos.com <br>
          </h4>
          <h4 v-else>
            
          </h4>
        </template>
        
      </div>

      <div class="row cover-footer">
        <div class="right">
          <a href="https://deltactivos.cl" target="_blank">
            <img
              class="cover-logo"
              src="/img/d-plus-color.png"
              alt="D-Plus Logo"
            >
          </a>
          <span>
            {{ localI18n.t('reporte.cover.generado_por') }} D-Plus
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const customParseFormat = require('dayjs/plugin/customParseFormat')
const localeData = require('dayjs/plugin/localeData');
const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

require('dayjs/locale/es');
require('dayjs/locale/en');
dayjs.locale('es');

export default {
  name: 'reporte-cover',
  props: {
    p_nom: 0,
    fecha_reporte: '',
    fecha_creacion: '',
    portafolio: {
      type: String,
      default: 'SOLCOR'
    },
    grupo_portafolio: {
      type: String,
      default: 'GDA'
    },
    nombre_visible: {
      type: String,
      default: ''
    },
    mail: {
      type: String,
      default: ''
    },
    localI18n: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      portafolios_delta: ['DAGDA', 'ENLASA', 'SINVEST1', 'SINVEST2']
    }
  },
  methods: {
    formatDate(date, formato){
      return date.locale(this.localI18n.locale).format(formato);
    }
  },
  created(){
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lusitana&display=swap');

body {
  // font-family: 'Lusitana', serif;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.page {
  font-family: 'Lusitana', serif;
  // font-family: Georgia, 'Times New Roman', Times, serif;
}

.subpage {
  /* padding: 1cm; */
  padding: 1cm 2cm;
  /* border: 5px #ccc solid; */
  height: 297mm;
  background-color: white;
  /* outline: 2cm whitesmoke solid; */

  &.report-cover {
    padding-top: 2cm;

    &#cover-title {
      margin-bottom: 0.25cm;
    }

    &#cover-title-2 {
      margin-bottom: 1cm;
      font-weight: bolder;

      > h1 {
        line-height: 1.1cm;
      }
    }
  }
}

.cover-header {
  margin-bottom: 2cm !important;
  padding-bottom: 0.5cm;
  // border-bottom: solid 1px;

  > .left {
    font-size: 15px;
    font-weight: 600;
    color: #000;
  }

  > .right {
    > .cover-logo {
      height: 70px;
      position: absolute;
      top: 70px;
      right: 2cm;
      border-radius: 0;
    }
  }

  // > hr {
  //   margin: 25px auto 1px auto;
  //   border: none;
  //   border-top: solid 1px #333;
  //   height: 1px;
  //   background-color: transparent;
  // }
}

.cover-footer {
  margin-bottom: 2cm !important;
  padding-bottom: 0.5cm;
  // border-bottom: solid 1px;

  > .left {
    font-size: 15px;
    font-weight: 600;
    color: #000;
  }

  > .right {
    position: absolute;
    right: 0.75cm;
    bottom: 0.25cm;
    display: flex;
    flex-direction: column;
    align-items: center;

    a > .cover-logo {
      height: 40px;
      width: 40px;
      margin-bottom: 10px;
      // position: absolute;
      // top: -70px;
      // right: 2cm;
      border-radius: 0;
    }
  }

  // > hr {
  //   margin: 25px auto 1px auto;
  //   border: none;
  //   border-top: solid 1px #333;
  //   height: 1px;
  //   background-color: transparent;
  // }
}


div.row {
  margin-bottom: 20px;
  /* border: solid 1px blue; */
}

#cover-logo {
  text-align: right;
  margin-bottom: 50px;
}

.cover-contact-info {
  flex-direction: column;
}

#cover-info {
  margin-top: 75px;
  margin-bottom: 75px;
}

.contact-name {
  font-weight: bold;
  font-size: 20px;
}

</style>